import * as React from 'react'
import { StaticImage } from 'gatsby-plugin-image'
import Layout from '../../../layouts'
import LargeHero from '../../../components/elements/largeHero'
import { Body, SizedText } from '../../../components/elements/typography'
import Grid from '../../../components/elements/grid'
import PoliciesMenu from "../../../components/molocules/menus/policiesMenu"
import SEO from '../../../components/elements/seo'

const TopBox = ({title, width, children}) => (
  <div css={{
    backgroundColor: `#fff`,
    //textAlign: `justify`,
    hyphens: `auto`,
    padding: `2rem 1.5rem 2rem 2rem`,
    '& p': {
      fontWeight: `600`,
      '& span': {
        fontWeight: `400`
      }
    }, 
  }}>
    <SizedText as="h2" smallSize="2.281" largeSize="1.802" css={{paddingBottom: `1rem`}}>{title}</SizedText>
    {children}
  </div>
)

const PatriotActPage = ({}) => {

  return (
    <Layout isSubMenuOpen={true} openAccountLink={true}>

      <SEO
        title="US PATRIOT Act | TCB Trust Center"
        description="To help the government fight the funding of terrorism and money laundering activities, Federal law requires all financial institutions to obtain, verify and record information that identifies 
        each person who opens an account or creates a new customer relationship with our bank."
        location="/about/policies/safe-act"
        ogImg='/images/social-graph/the-claxton-bank.jpg'
      />  

      <LargeHero.Container
        padding="3rem 0 4rem 0"
        background={{
          //backgroundColor: `#b2a08a`,
          backgroundImage: `url(/images/background-deltas.jpg)`, 
          backgroundRepeat: `no-repeat`, 
          backgroundAttachment: `scroll`, 
          backgroundPosition: `top left`, 
          backgroundSize: `cover`,
        }}
        breadcrumbLinks={[
          { path: "/", text: "Home" },
          { path: "/about/", text: "About Us" },
          { path: "/about/policies/", text: "Policies" },
          { path: "/about/policies/patriot-act", text: "PATRIOT Act" }
        ]}
        styles={{
          paddingBottom: `5rem`,
          overflow: `hidden`,
          '.breadcrumbs, .breadcrumbs [aria-current="page"]': {color: `#fff`},
          '.heroContent': { paddingBottom: `3rem`},
          '@media (min-width: 680px)': {
            '.heroContent': { width: `calc(72% - 1rem)`, marginBottom: `0`},
            '.heroImage': { width: `calc(40% - 1rem) !important`,},
          }
        }}
      >
        <LargeHero.Content styles={{paddingTop: `1rem`, color: `#fff`}}>
          <LargeHero.Eyebrow text="Policies & Disclosures" styles={{borderColor: `#fff !important`}}/>
          <h1 css={{fontFamily: `Proxima-nova`, textTransform: `uppercase`,  'span': {display: `block`, lineHeight: `1`, padding: `0.5rem 0`}}}>
            <SizedText as="span" smallSize="2.281" largeSize="4.624">US PATRIOT Act Disclosure</SizedText>
          </h1>
        </LargeHero.Content>
        <LargeHero.Image >
          <StaticImage 
              css={{
                '@media (min-width: 680px)': { display: 'none !important' }, 
                '@media (min-width: 1300px)': { display: 'block !important' } 
              }} 
              src="../../../images/patriot-act.png" 
              alt=""
            />
            <StaticImage 
              css={{
                display: `none !important`,  
                '@media (min-width: 680px)': { display: 'block !important' }, 
                '@media (min-width: 1300px)': { display: 'none !important' } 
              }} 
              src="../../../images/patriot-act_cropped.png" 
              alt=""
            />
        </LargeHero.Image>
      </LargeHero.Container>

      <Grid.FullWidthSection>
        <Grid.Block start={[2,2,2,2,2,2,3]} span={[12,12,12,12,8,8,7]} css={{
          paddingBottom: `4rem`,
          'p': {textAlign: `justify`, color: "#707070"},
          'h2': { marginTop: `2rem`, color: `#1d449e`, fontFamily: `proxima-nova`}
        }}>
          
          
          <SizedText as="h2" smallSize="1.424" largeSize="1.802" css={{padding: `2.5rem 0 1rem 0`}}>
            IMPORTANT INFORMATION about opening a <u>CONSUMER</u> account or creating a new customer relationship.
          </SizedText>
          <Body>
            To help the government fight the funding of terrorism and money laundering activities, Federal law requires all financial institutions to obtain, verify and record 
            information that identifies each person who opens an account or creates a new customer relationship with our bank.
            <br /><br />
            When you open an account, we will ask for your name, address, date of birth and other information that will allow us to identify you.  We may ask to see your driver’s 
            license or other identifying documents.
          </Body>


          <SizedText as="h2" smallSize="1.424" largeSize="1.802" css={{padding: `4rem 0 1rem 0`}}>
            IMPORTANT INFORMATION about opening a <u>LEGAL ENTITY</u> account or creating a new customer relationship.
          </SizedText>
          <Body>
            Effective May 1, 2018, new rules under the Bank Secrecy Act will aid the government in the fight against crimes to evade financial measures designed to combat terrorism 
            and other national security threats.
            <br /><br />
            Each time an account is opened for a covered Legal Entity, we are required to ask you for identifying information (name, address, date of birth, social security number as 
            well as identification documents) for:
            <br /><br />
            Each individual that has beneficial ownership (25% or more); and,<br />
            One individual that has significant managerial control, of the Legal Entity.
            <br /><br />
            If you are opening an account on behalf of a Legal Entity, you will be required to provide the appropriate documentation and to certify that this information is true and 
            accurate to the best of your knowledge.
          </Body>
        </Grid.Block>
        <Grid.Block start={[2,2,2,2,10,10]} span={[12,12,12,12,4,3]}
          css={{ 
            margin: `4rem 0`,
        }}>
          
          <PoliciesMenu />
        </Grid.Block>
      </Grid.FullWidthSection>
    </Layout> 
  )
}

export default PatriotActPage
